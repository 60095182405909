export const environment = {
  production: true,
  name: 'prod',
  apiHost: 'profile.service.onyxcentersource.com',
  apiUrl: 'https://profile.service.onyxcentersource.com/rest/public/',
  gecoPaymentUrl: 'https://payments.onyxcentersource.com/',
  commonServiceApiUrl: 'https://ocd.service.onyxcentersource.com/api/geco/',
  captchaSiteKey: '6Lde7tkUAAAAACopMVlCUo-Zv2Tkvdp7XWd3F-JL',
  captchaBaseUrl: 'https://www.recaptcha.net/recaptcha/api.js',
  cdnUrl: 'https://cdn.onyxcentersource.com/taprofile/',
  aprUrl: 'https://apr.onyxcentersource.com/',
};
